import Swiper from 'swiper/swiper-bundle.min.mjs';
import refs from '../refs/refs';

const swiperGallery = new Swiper('.js-residence__gallery', {
  loop: true,
  spaceBetween: 30,
  slidesPerView: 1,
  autoplay: {
    delay: 2000,
    disableOnInteraction: true,
  },
  speed: 500,
  navigation: {
    nextEl: '.residence__gallery-slider-btn-next',
    prevEl: '.residence__gallery-slider-btn-prev',
  },
  pagination: {
    el: '.residence__gallery-slider-paging',
    type: 'fraction',
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 'auto',
    },
    1200: {
      spaceBetween: 30,
      slidesPerView: 'auto',
    },
  },
});

const handleUpdateCurrentFraction = () => {
  const currentSlide = document.querySelector('.swiper-pagination-current');

  if (Number(currentSlide.textContent) >= 10) {
    return;
  }

  currentSlide.prepend('0');
};

const handleAddCountItems = () => {
  if (window.innerWidth >= 768) {
    refs.galleryCounterList.innerHTML = '';

    refs.galleryItems.forEach((item, idx) => {
      const element = `<li class="item ${
        idx + 1 === swiperGallery.realIndex + 1 ? 'is-active' : ''
      }">${idx + 1}</li>`;

      refs.galleryCounterList.insertAdjacentHTML('beforeend', element);
    });
  }
};

swiperGallery.on('slideChange', () => {
  handleUpdateCurrentFraction();

  if (window.innerWidth >= 768) {
    handleAddCountItems();
  }
});

const observerCb = (entries, slider) => {
  entries.forEach(({ isIntersecting }) => {
    if (isIntersecting) {
      slider.autoplay.start();
    } else {
      slider.autoplay.pause();
    }
  });
};

const observerSlider = new IntersectionObserver(
  entries => {
    observerCb(entries, swiperGallery);
  },
  {
    threshold: 0.5,
  }
);

observerSlider.observe(document.querySelector('.js-residence__gallery'));

handleAddCountItems();

handleUpdateCurrentFraction();

refs.galleryBtnNext.addEventListener('click', e => {
  refs.galleryBtnPrev.childNodes[1].classList.remove('is-active');
  e.currentTarget.childNodes[1].classList.add('is-active');
});

refs.galleryBtnPrev.addEventListener('click', e => {
  refs.galleryBtnNext.childNodes[1].classList.remove('is-active');
  e.currentTarget.childNodes[1].classList.add('is-active');
});
