const refs = {
  header: document.querySelector('.js-header'),
  burgerBtn: document.querySelector('.js-header__burger'),
  menuContainer: document.querySelector('.js-header__menu-container'),
  closeMenu: document.querySelector('.js-header__close-menu-btn'),

  bgWaves: document.querySelectorAll('.js-wave-effect'),

  hero: document.querySelector('.js-hero'),

  donwBtn: document.querySelector('.js-hero__down-btn'),

  bgTexts: document.querySelectorAll('.js-bg-text'),

  aboutImgBox: document.querySelector('.js-about__img-box'),

  personBenefitsList: document.querySelector('.js-person-benefits__list'),

  galleryBtnNext: document.querySelector('.js-swiper-button-next'),
  galleryBtnPrev: document.querySelector('.js-swiper-button-prev'),
  galleyPaging: document.querySelector('.js-swiper-pagination'),
  galleryItems: document.querySelectorAll(
    '.js-residence__gallery .swiper-slide'
  ),
  galleryCounterList: document.querySelector(
    '.js-residence__gallery-count-list'
  ),
  galleryContainer: document.querySelector('.js-residence__gallery'),

  spinSuns: document.querySelectorAll('.js-spin-sun'),

  reviewsList: document.querySelector('.js-reviews__list'),
  reviewsListItems: document.querySelectorAll('.js-reviews__list-item'),
  reviewsListImages: document.querySelectorAll('.js-reviews__list img'),
  showAllReviewsBtn: document.querySelector('.js-reviews__show-btn'),

  faqList: document.querySelector('.js-faq__list'),
  faqItems: document.querySelectorAll('.js-faq__list-item'),
  faqQuestionBtns: document.querySelectorAll('.js-faq-question'),
  faqOrders: document.querySelectorAll('.js-faq-order'),

  formMain: document.querySelector('.js-main-form'),
  formModal: document.querySelector('.js-modal-form'),

  backdropForm: document.querySelector('.js-backdrop.form'),
  closeModalForm: document.querySelector('.js-modal__close-btn.form'),

  backdropInfo: document.querySelector('.js-backdrop.info'),
  modalBoxes: document.querySelectorAll('.js-modal-info__box'),
  rulesList: document.querySelector('.js-footer__rules-list'),

  currentYear: document.querySelector('.js-current-year'),
};

export default refs;
