import refs from '../refs/refs';

export const handleToggleMenu = () => {
  const { menuContainer } = refs;

  menuContainer.classList.toggle('is-show');

  if (menuContainer.classList.contains('is-show')) {
    document.body.style.overflow = 'hidden';
    refs.donwBtn.style.display = 'none';
  } else {
    document.body.style.overflow = 'auto';
    refs.donwBtn.style.display = 'flex';
  }
};

refs.burgerBtn.addEventListener('click', handleToggleMenu);
refs.closeMenu.addEventListener('click', handleToggleMenu);
