import refs from '../refs/refs';

let initialValue = 0;

const handleSpinSun = () => {
  refs.spinSuns.forEach(item => {
    item.style.transform = `translateX(-50%) rotate(${window.scrollY / 10}deg)`;
  });
};

window.addEventListener('scroll', handleSpinSun);
