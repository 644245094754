import refs from '../refs/refs';

const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.intersectionRatio >= 1) {
        entry.target.classList.add('is-effect');
      }
    });
  },
  { threshold: 1 }
);

const items = refs.personBenefitsList.querySelectorAll('.background');

items.forEach(item => {
  observer.observe(item);
});
