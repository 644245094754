import refs from '../refs/refs';

const initOrders = () => {
  refs.faqItems.forEach((item, idx) => {
    refs.faqOrders[idx].textContent = `0${idx + 1}.`;
  });
};

const handleToggleFaq = () => {
  refs.faqItems.forEach(item => {
    const box = item.querySelector('.faq__question-box');
    const answer = item.querySelector('.faq__list-item .answer');
    const btnPlus = item.querySelector('.js-faq-btn-plus');
    const btnMinus = item.querySelector('.js-faq-btn-minus');

    box.addEventListener('click', () => {
      if (!item.classList.contains('is-show')) {
        const openItem = document.querySelector('.faq__list-item.is-show');
        if (openItem) {
          const openAnswer = openItem.querySelector('.faq__list-item .answer');
          const openBtnPlus = openItem.querySelector('.js-faq-btn-plus');
          const openBtnMinus = openItem.querySelector('.js-faq-btn-minus');

          openItem.classList.remove('is-show');
          openItem.style.height = `75px`;
          openAnswer.classList.remove('animate__fadeIn');
          openBtnPlus.classList.add('is-show');
          openBtnMinus.classList.remove('is-show');
        }
      }

      item.classList.toggle('is-show');
      item.style.height = `${answer.clientHeight + 100}px`;
      answer.classList.add('animate__fadeIn');

      if (!item.classList.contains('is-show')) {
        item.style.height = `75px`;
        answer.classList.remove('animate__fadeIn');
        btnPlus.classList.add('is-show');
        btnMinus.classList.remove('is-show');
      }

      if (item.classList.contains('is-show')) {
        btnPlus.classList.remove('is-show');
        btnMinus.classList.add('is-show');
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', handleToggleFaq);

initOrders();
