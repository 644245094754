import refs from '../refs/refs';

const handleCloseModal = () => {
  refs.backdropInfo.classList.remove('is-show');

  refs.modalBoxes.forEach(item => item.classList.remove('is-show'));

  document.body.style.overflow = 'auto';
};

const handleCloseOnBackdrop = e => {
  if (e.target === e.currentTarget) {
    handleCloseModal();
  }
};

const handleOpenModal = e => {
  if (!e.target.classList.contains('footer__rule-btn')) {
    return;
  }
  const id = e.target.dataset.id;

  refs.modalBoxes.forEach(item => {
    if (id === item.dataset.id) {
      item.classList.add('is-show');
      const btn = item.querySelector('.js-modal__close-btn.info');
      btn.addEventListener('click', handleCloseModal);

      document.body.style.overflow = 'hidden';
    }
  });

  refs.backdropInfo.classList.add('is-show');
};

refs.rulesList.addEventListener('click', handleOpenModal);
refs.backdropInfo.addEventListener('click', handleCloseOnBackdrop);
