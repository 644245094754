import refs from '../refs/refs';
import { scrollToTarget } from '../common/scroll-to-target';
import { handleToggleMenu } from './burger';

const handleScrollToSection = e => {
  if (!e.target.classList.contains('js-nav__link')) {
    return;
  }

  if (window.innerWidth < 992) {
    handleToggleMenu();
  }

  const target = document.getElementById(e.target.dataset.id);

  scrollToTarget(target);
};

refs.menuContainer.addEventListener('click', handleScrollToSection);
