import refs from '../refs/refs';
import { scrollToTarget } from '../common/scroll-to-target';

const handleShowAllReviews = e => {
  refs.reviewsList.classList.toggle('is-show');

  if (refs.reviewsList.classList.contains('is-show')) {
    refs.showAllReviewsBtn.textContent = 'Приховати';
    refs.showAllReviewsBtn.classList.add('is-show');

    refs.reviewsListItems.forEach((item, idx) => {
      item.classList.add('animate__fadeInUpBig');
    });

    if (window.innerWidth < 992) {
      scrollToTarget(e.currentTarget);
    }
  } else {
    refs.showAllReviewsBtn.textContent = 'Дивитися всі відгуки';
    refs.showAllReviewsBtn.classList.remove('is-show');

    refs.reviewsListItems.forEach((item, idx) => {
      item.classList.remove('animate__fadeInUpBig');
    });

    if (window.innerWidth < 992) {
      scrollToTarget(document.getElementById('reviews'));
    }
  }
};

refs.showAllReviewsBtn.addEventListener('click', handleShowAllReviews);
