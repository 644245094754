import Swiper from 'swiper/swiper-bundle.min.mjs';

const swiperInfoCump = new Swiper('.info-camp__slider', {
  loop: true,
  freeMode: true,
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 2500,
  autoplay: {
    delay: 0,
    disableOnInteraction: true,
  },
});

const handleDestroySwiper = () => {
  if (window.innerWidth >= 600) {
    swiperInfoCump.destroy();
  }
};

handleDestroySwiper();
