import refs from '../refs/refs';

const handleOpenModal = e => {
  if (!e.target.classList.contains('js-btn-cb-modal')) {
    return;
  }

  refs.backdropForm.classList.add('is-show');

  document.body.style.overflow = 'hidden';
};

const handleCloseModal = e => {
  refs.backdropForm.classList.remove('is-show');
  document.body.style.overflow = 'auto';
};

const handleCloseOnBackdrop = e => {
  if (e.target === e.currentTarget) {
    handleCloseModal();
  }
};

refs.hero.addEventListener('click', handleOpenModal);
refs.header.addEventListener('click', handleOpenModal);
refs.closeModalForm.addEventListener('click', handleCloseModal);
refs.backdropForm.addEventListener('click', handleCloseOnBackdrop);
document
  .querySelector('.help-children')
  .addEventListener('click', handleOpenModal);
document.querySelector('.footer').addEventListener('click', handleOpenModal);
