import refs from '../refs/refs';
import { scrollToTarget } from '../common/scroll-to-target';

const scrollToBodyTop = () => {
  scrollToTarget(document.body);
};

const handleSpinBtn = () => {
  const iconCircle = refs.donwBtn.querySelector('.icon');
  const iconArrow = refs.donwBtn.querySelector('.icon-arrow');
  const iconCircleEnd = refs.donwBtn.querySelector('.icon-circle');

  const windowHeight = window.innerHeight;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const scrollPosition =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

  if (windowHeight + scrollPosition >= documentHeight - 300) {
    iconCircle.classList.add('is-hidden');
    iconCircleEnd.classList.add('is-end');
    iconArrow.classList.add('is-rotate');
    refs.donwBtn.addEventListener('click', scrollToBodyTop);
  } else {
    iconCircle.classList.remove('is-hidden');
    iconCircleEnd.classList.remove('is-end');
    iconArrow.classList.remove('is-rotate');
    refs.donwBtn.removeEventListener('click', scrollToBodyTop);
  }

  if (window.scrollY > 300) {
    if (window.innerWidth < 992) {
      iconCircle.style.transform = `rotate(${window.scrollY / 3}deg)`;
    }
    iconCircle.style.transform = `rotate(${window.scrollY / 10}deg)`;
    refs.donwBtn.classList.add('is-fixed');
  } else {
    refs.donwBtn.classList.remove('is-fixed');
  }
};

window.addEventListener('scroll', handleSpinBtn);
