import Cleave from 'cleave.js';
import refs from '../refs/refs';
import {
  getInputName,
  getInputPhone,
  getErrorInputName,
  getErrorInputPhone,
  getErrorIconName,
  getErrorIconPhone,
} from '../refs/get-refs';

let cleave;
const phoneRegExp = /[^\d+^-]/g;

const handleFocusInput = (e, selector, name) => {
  const label = document.querySelector(`${selector} .js-label-text.${name}`);
  label.classList.add('is-hidden');
};
const handleBlurInput = (e, selector, name) => {
  const label = document.querySelector(`${selector} .js-label-text.${name}`);
  if (e.currentTarget.value !== '') {
    return;
  }
  label.classList.remove('is-hidden');
};

const handleInputName = (e, selector) => {
  e.currentTarget.classList.remove('is-error');
  const label = getErrorInputName(selector);
  const icon = getErrorIconName(selector);

  label.classList.remove('is-error');
  icon.classList.remove('is-error');
};

const handleInputPhone = (e, selector) => {
  e.currentTarget.classList.remove('is-error');
  const label = getErrorInputPhone(selector);
  const icon = getErrorIconPhone(selector);

  label.classList.remove('is-error');
  icon.classList.remove('is-error');
};

const handleInputPhoneOnlyNumbers = e => {
  const value = e.target.value;
  const valueArr = value.split('');
  const result = valueArr.filter(item => !phoneRegExp.test(item)).join('');

  e.target.value = result;
};

// Main Form
getInputPhone('.js-main-form').addEventListener(
  'input',
  handleInputPhoneOnlyNumbers
);
getInputName('.js-main-form').addEventListener('input', e => {
  handleInputName(e, '.js-main-form');
});
getInputPhone('.js-main-form').addEventListener('input', e => {
  handleInputPhone(e, '.js-main-form');
});
getInputPhone('.js-main-form').addEventListener('focus', e => {
  if (e.target.value.length <= 3) {
    cleave = new Cleave(e.target, {
      prefix: '+380',
      delimiter: '-',
      blocks: [6, 3, 2, 2],
      uppercase: true,
    });
  } else return;
});
getInputPhone('.js-main-form').addEventListener('blur', e => {
  if (e.target.value.length <= 4) {
    cleave.destroy();
    e.target.value = '';
  } else return;
});
getInputName('.js-main-form').addEventListener('focus', e => {
  handleFocusInput(e, '.js-main-form', 'name');
});
getInputName('.js-main-form').addEventListener('blur', e => {
  handleBlurInput(e, '.js-main-form', 'name');
});
getInputPhone('.js-main-form').addEventListener('focus', e => {
  handleFocusInput(e, '.js-main-form', 'phone');
});
getInputPhone('.js-main-form').addEventListener('blur', e => {
  handleBlurInput(e, '.js-main-form', 'phone');
});

// Modal Form
getInputPhone('.js-modal-form').addEventListener(
  'input',
  handleInputPhoneOnlyNumbers
);
getInputName('.js-modal-form').addEventListener('input', e => {
  handleInputName(e, '.js-modal-form');
});
getInputPhone('.js-modal-form').addEventListener('input', e => {
  handleInputPhone(e, '.js-modal-form');
});
getInputPhone('.js-modal-form').addEventListener('focus', e => {
  if (e.target.value.length <= 3) {
    cleave = new Cleave(e.target, {
      prefix: '+380',
      delimiter: '-',
      blocks: [6, 3, 2, 2],
      uppercase: true,
    });
  } else return;
});
getInputPhone('.js-modal-form').addEventListener('blur', e => {
  if (e.target.value.length <= 4) {
    cleave.destroy();
    e.target.value = '';
  } else return;
});
getInputName('.js-modal-form').addEventListener('focus', e => {
  handleFocusInput(e, '.js-modal-form', 'name');
});
getInputName('.js-modal-form').addEventListener('blur', e => {
  handleBlurInput(e, '.js-modal-form', 'name');
});
getInputPhone('.js-modal-form').addEventListener('focus', e => {
  handleFocusInput(e, '.js-modal-form', 'phone');
});
getInputPhone('.js-modal-form').addEventListener('blur', e => {
  handleBlurInput(e, '.js-modal-form', 'phone');
});
