import { getSection } from '../refs/get-refs';
import throttle from 'lodash.throttle';

let initialValue = 0;

const handleWaveEffect = (section, Yposition) => {
  const sectionBg = getSection(section);

  if (window.innerWidth < 992) {
    sectionBg.style.backgroundPosition = `${(initialValue +=
      1 / 5)}em ${Yposition}`;
  } else {
    sectionBg.style.backgroundPosition = `${(initialValue +=
      1 / 2)}em ${Yposition}`;
  }
};

const handleWaveEffectMinus = (section, Yposition) => {
  const sectionBg = getSection(section);

  if (window.innerWidth < 992) {
    sectionBg.style.backgroundPosition = `-${(initialValue +=
      1 / 5)}em ${Yposition}`;
  } else {
    sectionBg.style.backgroundPosition = `-${(initialValue +=
      1 / 2)}em ${Yposition}`;
  }
};

const handleWaveEffectAboutSection = section => {
  const sectionBg = getSection(section);

  if (window.innerWidth < 768) {
    sectionBg.style.backgroundPosition = `center top, center bottom, ${(initialValue +=
      1 / 5)}em bottom`;
  } else {
    sectionBg.style.backgroundPosition = `-${(initialValue +=
      1 / 2)}em top, center 102%, ${(initialValue += 1 / 2)}em bottom`;
  }
};

if (window.innerWidth >= 768) {
  window.addEventListener(
    'scroll',
    throttle(e => {
      handleWaveEffect('.info-camp', 'top');
    }, 400)
  );
}

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectAboutSection('.about');
  }, 400)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectMinus('.quote', 'bottom');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectMinus('.quote-2', 'bottom');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectMinus('.quote-3', 'bottom');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffect('.help-children', 'center');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectMinus('.residence', 'center');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffect('.for-parents', 'center');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffect('.camp-benefits', 'center');
  }, 300)
);

window.addEventListener(
  'scroll',
  throttle(e => {
    handleWaveEffectMinus('.reviews', 'center');
  }, 300)
);
